import { render, staticRenderFns } from "./TransactionListItem.vue?vue&type=template&id=5180c9f2&scoped=true&"
import script from "./TransactionListItem.vue?vue&type=script&lang=js&"
export * from "./TransactionListItem.vue?vue&type=script&lang=js&"
import style0 from "./TransactionListItem.vue?vue&type=style&index=0&id=5180c9f2&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5180c9f2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileAvatar } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileSubTitle } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VBtn,VDivider,VIcon,VListTile,VListTileAction,VListTileAvatar,VListTileContent,VListTileSubTitle,VListTileTitle})
