import { render, staticRenderFns } from "./TransactionTemplate.vue?vue&type=template&id=6b1a1d8f&scoped=true&"
import script from "./TransactionTemplate.vue?vue&type=script&lang=js&"
export * from "./TransactionTemplate.vue?vue&type=script&lang=js&"
import style0 from "./TransactionTemplate.vue?vue&type=style&index=0&id=6b1a1d8f&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b1a1d8f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VDivider,VIcon,VLayout,VList,VListTile,VListTileContent,VListTileTitle})
